"use strict";

document.addEventListener("DOMContentLoaded", () => {
	window.customElements.define(
		"deferred-iframe",
		class extends HTMLElement {
			constructor() {
				super();
				this.replaced = false;
				this.texts =
					typeof deferredIframeStrings !== undefined
						? deferredIframeStrings
						: {
								placeholder: `Zobrazení tohoto obsahu od {{domain}} vyžaduje souhlas s&nbsp;uložením cookies třetích stran.`,
								analytics: "Analytické cookies",
								marketing: "Marketingové cookies",
								preferences: "Preferenční cookies",
						  };
				this.defaultCookieStatusEndpoint = "/cookies-status";
			}

			connectedCallback() {
				this.shadow = this.attachShadow({ mode: "open" });
				this.frame = document.createElement("iframe");
				this.source = this.getAttribute("src");
				this._storageKey = `deffered-frame-${this.source}`;

				this.requirements = this.hasAttribute("requires")
					? this.getAttribute("requires").split(" ")
					: [];

				this.frame.className = this.className;

				[
					"title",
					"allow",
					"allowfullscreen",
					"referrerpolicy",
					"loading",
					"width",
					"height",
				].forEach((attr) => {
					if (this.hasAttribute(attr)) {
						this.frame[attr] = this.getAttribute(attr);
					}
				});

				this.createStyles();

				if (this.hasAttribute("title")) {
					const title = document.createElement("p");
					title.innerHTML = `<strong>${this.getAttribute(
						"title"
					)}</strong>`;
					title.part = "title";

					this.shadow.append(title);
				}

				const placeholder = document.createElement("p");
				placeholder.innerHTML = this.texts.placeholder.replace(
					"{{domain}}",
					new URL(this.source).hostname
				);
				placeholder.part = "explanation";

				const listCookies = document.createElement("ul");
				listCookies.part = "requirements";

				for (let [key, value] of Object.entries({
					analytics: this.texts.analytics,
					marketing: this.texts.marketing,
					preferences: this.texts.preferences,
				})) {
					if (this.requirements.includes(key)) {
						listCookies.insertAdjacentHTML(
							"beforeend",
							`<li part="requirement">${value}</li>`
						);
					}
				}

				this.shadow.append(placeholder, listCookies);

				let cookieEndpoint = this.defaultCookieStatusEndpoint;

				if (this.hasAttribute("cookie-endpoint")) {
					cookieEndpoint = this.getAttribute("cookie-endpoint");
				}

				fetch(cookieEndpoint)
					.then((response) => response.json())
					.then((data) => {
						this.handleConsent(data);
					})
					.catch((error) => {
						console.error(error);
					});

				document.addEventListener("cookieconsent", (event) => {
					const status = event.detail;
					this.handleConsent(status);
				});
			}

			handleConsent(consent) {
				if (
					this.checkRequirements(
						consent.agree_analytics,
						consent.agree_marketing,
						consent.agree_preferences
					)
				) {
					this.replaceContent();
				} else {
					console.info(
						"Nedostatečná povolení, obsah <iframe> nebude zobrazen"
					);
				}
			}

			checkRequirements(analytics, marketing, preferences) {
				return (
					Number(analytics) >=
						Number(this.requirements.includes("analytics")) &&
					Number(marketing) >=
						Number(this.requirements.includes("marketing")) &&
					Number(preferences) >=
						Number(this.requirements.includes("preferences"))
				);
			}

			replaceContent() {
				if (this.replaced) return;
				this.frame.src = this.source;
				this.parentNode.insertBefore(this.frame, this);
				this.remove();
				sessionStorage.setItem(this._storageKey, 1);
				this.replaced = true;
			}

			createStyles() {
				const style = document.createElement("style");

				const width = `${this.frame.width}px`;
				const height = `${this.frame.height}px`;

				style.textContent = `
					:host([src]) {
						display: flex !important;
						flex-direction: column !important;
						justify-content: center !important;
						align-items: center !important;
						/* aspect-ratio: ${this.frame.width} / ${this.frame.height} !important; */
						width: ${width};
						height: ${height};
						max-width: 100% !important;
						background-color: rgb(0 0 0 / .05);
						font-size: 1rem !important;
						font-family: system-ui, -apple-system !important;
						box-sizing: border-box !important;
						padding: 2rem !important;
					}

					p {
						text-align: center;
						font-size: 15px;
						margin: 1em 0;
						line-height: 1.2;
					}

					p + p {
						margin-top: 0;
					}

					ul {
						padding: 0;
						margin: 0;
						list-style-type: none;
						text-align: left;
						font-size: 15px;
					}

					li::before {
						content: "🍪";
						margin: 0 .5em 0 0;
					}
				`;

				this.shadow.append(style);
			}
		}
	);
});
